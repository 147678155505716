import React from 'react'
// import { Job } from '../components/Job'
// import { SEO } from '../components/SEO'
// import { JobLayout } from '../layouts/JobLayout'
import { Job as JobType } from '../../utils/greenhouse'
import JobPageTemplate from '../../templates/JobPageTemplate'

const SupplyChainLead = () => {
  const job: JobType = {
    id: '4',
    title: "Supply Chain Lead",
    location: {
        name: "Los Angeles/Remote"
    },
    metadata: [
      {
        value: {
          min_value: "",
          max_value: "",
          unit: "USD"
        },
        value_type: "",
        name: "Salary Range"
      }
    ],
    content: `
      <div class="article">
        <div>
          <p><strong>About us</strong></p>
          <p><span style="font-weight: 400;">Fourthwall is a platform that helps creative people launch brands. Whether you’re dreaming about a fashion line, a unique hot sauce, or something digital, we make the process easy by handling all the operations and logistics—from manufacturing to fulfillment to customer support. Our goal is to empower creative people to bring their ideas to life without requiring them to become e-commerce experts.</span></p>
          <p><span style="font-weight: 400;">Our culture is built around the philosophy of putting our customers first. If you are talented, hardworking, and love helping others, we would love to talk to you about joining our team.</span></p>
          <p><span style="font-weight: 400;">Our headquarters are in Los Angeles, USA, and Poznan, Poland. We prefer people willing to work at least a couple days a week out of one of our offices but are open to remote work for the right person. If you want to find out more about who we are and how we work, you can check our <a href="https://cdn.fourthwall.com/public/fourthwall/Fourthwall%20Values.pdf" target="_blank">Company Values</a>.</span></p>
        </div>

        <div>
          <p><strong>About the role</strong></p>
          <p><span style="font-weight: 400;">We are looking for a world-class operations leader to help us scale from 100,000 creators to 1,000,000.</span></p>
          <p><span style="font-weight: 400;">While we started with a focus on on-demand manufacturing, our creators are increasingly looking for help sourcing unique items like plushies, hot sauces, candy, and other CPG products that cannot be fully automated. Our hope is to find a leader that can better help us serve that need.</span></p>
          <p><span style="font-weight: 400;">While this is a leadership role, it will also require significant hands on work. We pride ourselves on being a small team of overachievers.</span></p>
        </div>

        <div>
          <p><strong>Key Responsibilities:</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Source world-class manufacturing partners</strong>. Establish and maintain strategic relationships with key suppliers and manufacturers to ensure consistent quality and availability of products.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Cost Management</strong>. Optimize procurement processes to manage costs effectively while maintaining high standards of product quality.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Drive Automations & Implementation</strong>. Work directly with our product and engineering teams to automate key flows to make the process efficient for both creators and manufacturers.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Customer Feedback Integration</strong>. Collect and analyze feedback from creators to continuously refine and improve product offerings and operational processes.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong>Training and Development</strong>. Mentor and develop a high-performing operations team, fostering a culture of excellence and continuous improvement.</span></li>
          </ul>
        </div>

        <div>
          <p><strong>What you likely have</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">10+ years of experience in product sourcing, supply chain, or operations management</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Experience working and negotiating with manufacturing partners outside of the USA</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Ability to thrive in a fast-paced, results-driven environment</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">An ambition to prove you’re one of the best in the world at what you do</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">An understanding of the world of content creators (YouTubers, podcasters, Tiktokkers, etc.)</span></li>
          </ul>
        </div>

        <div>
          <p><strong>The perks</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Owning a significant role in a rapidly growing company</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Competitive compensation package</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Equity stake in Fourthwall</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Medical, Dental & Vision Insurance</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">401(k)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Paid Parental leave, including non-birthing parents</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Flexible PTO policy</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Work in a team of experienced experts and amazing people</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Top-of-the-line Apple equipment tailored to your requirements</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Dog-friendly office just one block from the beach in LA</span></li>
          </ul>
        </div>

        <div>
          <p><span style="font-weight: 400;"><i>We are an equal opportunity employer. We value a diverse workforce and an inclusive culture. We encourage applications from all qualified individuals without regard to race, color, religion, gender, sexual orientation, gender identity or expression, age, national origin, marital status, disability, and veteran status.</i></span></p>
        </div>
      </div>
    `
  }

  return (
    <div>
      <JobPageTemplate pageContext={{
          job
      }} />
    </div>
  )
}

export default SupplyChainLead